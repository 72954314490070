export default defineAppConfig({
  ui: {
    strategy: 'override',
    primary: 'blue',
    avatar: {
      size: {
        xl: 'h-32 w-32',
      },
    },
  },
})
