<script setup>
const error = useError()

function handleError() {
  clearError({
    redirect: '/',
  })
}
</script>

<template>
  <NuxtLayout>
    <div class="flex flex-col items-center justify-center">
      <template v-if="error.statusCode === 404">
        <h1 class="text-primary font-bold text-4xl">
          404!
        </h1>
        <p>Sorry, that page doesn't exist.</p>
      </template>
      <template v-else>
        <h1>Dang</h1>
        <p>
          <strong>{{ error.message }}</strong>
        </p>
        <p>It looks like something broke.</p>
        <p>Sorry about that.</p>
      </template>
      <p>
        <UButton class="mt-4" variant="outline" @click="handleError">
          Go back
        </UButton>
      </p>
    </div>
  </NuxtLayout>
</template>
