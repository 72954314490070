import revive_payload_client_RHCSA35S78 from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_rollup@4.9.6_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1kSyX0ztDU from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_rollup@4.9.6_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_LCgsEEBbq3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_rollup@4.9.6_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_OBsAePMNq8 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.1.5_rollup@4.9.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_xCyY3VVBI8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_rollup@4.9.6_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_ShyGwXAkpz from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_rollup@4.9.6_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_B7ui2p9QJB from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_rollup@4.9.6_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_zcL6JDOxdE from "/vercel/path0/node_modules/.pnpm/@productdevbook+chatwoot@1.4.0_rollup@4.9.6_vue@3.4.15/node_modules/@productdevbook/chatwoot/dist/runtime/plugin.mjs";
import i18n_hkjl6HB8gd from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n-edge@8.0.0-28432155.c9bdcd7_rollup@4.9.6_vue@3.4.15/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.mjs";
import colors_iFxL60Gsyq from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.12.3_nuxt@3.9.3_rollup@4.9.6_vite@5.0.12_vue@3.4.15/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_xoUv12O2Wu from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_rollup@4.9.6/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import auth_redirect_e3HFrYn8ts from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.1.5_rollup@4.9.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import chunk_reload_client_IKAAy0d06D from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_rollup@4.9.6_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_RHCSA35S78,
  unhead_1kSyX0ztDU,
  router_LCgsEEBbq3,
  supabase_client_OBsAePMNq8,
  payload_client_xCyY3VVBI8,
  check_outdated_build_client_ShyGwXAkpz,
  components_plugin_KR1HBZs4kY,
  prefetch_client_B7ui2p9QJB,
  plugin_zcL6JDOxdE,
  i18n_hkjl6HB8gd,
  colors_iFxL60Gsyq,
  plugin_client_xoUv12O2Wu,
  auth_redirect_e3HFrYn8ts,
  chunk_reload_client_IKAAy0d06D
]