import { default as buyPTGS4Eqc58Meta } from "/vercel/path0/pages/buy.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as _91service_93nQyG3tV36qMeta } from "/vercel/path0/pages/manager/zomro/services/[service].vue?macro=true";
import { default as indexNDYAfQ2Q9gMeta } from "/vercel/path0/pages/manager/zomro/services/index.vue?macro=true";
import { default as managerwxoZK4jbTOMeta } from "/vercel/path0/pages/manager.vue?macro=true";
import { default as serversIbbNybMAGCMeta } from "/vercel/path0/pages/servers.vue?macro=true";
import { default as workspaceXl31e6xGrpMeta } from "/vercel/path0/pages/workspace.vue?macro=true";
export default [
  {
    name: buyPTGS4Eqc58Meta?.name ?? "buy",
    path: buyPTGS4Eqc58Meta?.path ?? "/buy",
    meta: buyPTGS4Eqc58Meta || {},
    alias: buyPTGS4Eqc58Meta?.alias || [],
    redirect: buyPTGS4Eqc58Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/buy.vue").then(m => m.default || m)
  },
  {
    name: confirm2uAIxw9fKZMeta?.name ?? "confirm",
    path: confirm2uAIxw9fKZMeta?.path ?? "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    alias: confirm2uAIxw9fKZMeta?.alias || [],
    redirect: confirm2uAIxw9fKZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: managerwxoZK4jbTOMeta?.name ?? "manager",
    path: managerwxoZK4jbTOMeta?.path ?? "/manager",
    children: [
  {
    name: _91service_93nQyG3tV36qMeta?.name ?? "manager-zomro-services-service",
    path: _91service_93nQyG3tV36qMeta?.path ?? "zomro/services/:service()",
    meta: _91service_93nQyG3tV36qMeta || {},
    alias: _91service_93nQyG3tV36qMeta?.alias || [],
    redirect: _91service_93nQyG3tV36qMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/manager/zomro/services/[service].vue").then(m => m.default || m)
  },
  {
    name: indexNDYAfQ2Q9gMeta?.name ?? "manager-zomro-services",
    path: indexNDYAfQ2Q9gMeta?.path ?? "zomro/services",
    meta: indexNDYAfQ2Q9gMeta || {},
    alias: indexNDYAfQ2Q9gMeta?.alias || [],
    redirect: indexNDYAfQ2Q9gMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/manager/zomro/services/index.vue").then(m => m.default || m)
  }
],
    meta: managerwxoZK4jbTOMeta || {},
    alias: managerwxoZK4jbTOMeta?.alias || [],
    redirect: managerwxoZK4jbTOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/manager.vue").then(m => m.default || m)
  },
  {
    name: serversIbbNybMAGCMeta?.name ?? "servers",
    path: serversIbbNybMAGCMeta?.path ?? "/servers",
    meta: serversIbbNybMAGCMeta || {},
    alias: serversIbbNybMAGCMeta?.alias || [],
    redirect: serversIbbNybMAGCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/servers.vue").then(m => m.default || m)
  },
  {
    name: workspaceXl31e6xGrpMeta?.name ?? "workspace",
    path: workspaceXl31e6xGrpMeta?.path ?? "/workspace",
    meta: workspaceXl31e6xGrpMeta || {},
    alias: workspaceXl31e6xGrpMeta?.alias || [],
    redirect: workspaceXl31e6xGrpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/workspace.vue").then(m => m.default || m)
  }
]